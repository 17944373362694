<template>
  <div class="view-wrapper feed" v-visibility-change="visibilityChange">
    <div class="feed-wrapper" :class="{ 'no-results' : (!feed || feed.length === 0) && !showFeed}">
      <div class="feed-wrapper-in">
        <transition name="fade-feed">
          <no-outfits
            v-if="!loading && (!feed || feed.length === 0) && !showFeed"
            @addOutfit="handleOutfit">
          </no-outfits>
        </transition>
        <transition name="fade-feed">
          <!-- {{ feed }} -->
          <feed
            v-if="!loading && showFeed && feed && feed.user !== 'undefined'"
            :feed="feed"
            @handleNewFeed="searchFeed"
            @handleMore="handleMore"
            @handleRate="handleRating"
            @reloadOutfit="toggleReloadOutfit"
            @saveOutfit="toggleSaveOutfit">
          </feed>
        </transition>
        <transition name="fade-feed">
          <Loader v-if="loading"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NoOutfits from '@/components/outfit/NoOutfits'
import Feed from '@/components/outfit/Feed'
import Loader from '@/components/icons/loader'

export default {
  metaInfo: {
    title: 'Home',
    meta: [
      {
        name: 'description',
        content: 'Let our inclusive community help you decide what to wear! Express your personal style and share your looks on the app'
      }
      // Add other meta tags as needed
    ]
  },
  components: {
    NoOutfits,
    Feed,
    Loader
  },
  data () {
    return {
      noOutfits: true,
      outfitModal: false,
      showFeed: false,
      loading: true,
      imageCache: null,
      outfitReloadCount: 0
    }
  },
  computed: {
    ...mapState('outfit', ['feed', 'cretate'])
  },
  methods: {
    ...mapActions('outfit', ['searchFeed', 'toggleCreate', 'rateFeed', 'hideFeed', 'reportFeed', 'getOutfit', 'toggleFavourite']),
    visibilityChange (evt, hidden) {
      if (!hidden) {
        this.getFeed()
      }
      // if (!hidden && this.showFeed === false) {
      //   this.getFeed()
      // }
    },
    handleOutfit (val) {
      this.toggleCreate(val)
    },
    handleRating (rate, item) {
      if (item.hasOwnProperty('isImageFound') && item.isImageFound === false) { return }
      this.rateFeed({ rating: parseInt(rate), outfit_item_id: item.id }).then(resp => {
        if (!resp) this.showFeed = false
        resp.is_rated && setTimeout(() => {
          this.getFeed()
        }, 800)
      })
    },
    handleMore (type) {
      type === 1
        ? this.hideFeed({ batch_id: this.feed.id }).then(resp => {
          this.getFeed()
        })
        : this.reportFeed({ batch_id: this.feed.id }).then(resp => {
          this.getFeed()
        })
    },
    getFeed () {
      // let self = this
      this.loading = true
      this.imageCache = []
      this.searchFeed().then((resp) => {
        // debugger
        for (let i = 0; i < resp.outfit_items.length; i++) {
          let a = new Image()
          a.src = this.$store.state.auth.base.baseFilePath + resp.outfit_items[i].image_path
          this.imageCache.push(a)
        }
        this.showFeed = resp
        this.subscribeEvent()
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }).catch(() => {
        this.showFeed = false
        setTimeout(() => {
          this.loading = false
        }, 1000)
        // this.loading = false
      })
    },
    subscribeEvent () {
      if (this.feed) {
        let pusher = new window.Pusher(process.env.VUE_APP_PUSHER_KEY, {
          cluster: 'eu',
          encrypted: false
        })
        let self = this
        var channel = pusher.subscribe('outfit-' + self.feed.id)
        channel.bind('outfit.rated', function () {
          self.getFeed()
        })
      }
    },
    toggleReloadOutfit () {
      if (this.outfitReloadCount > 1) {
        this.handleMore(1)
      } else {
        this.outfitReloadCount++
        this.getOutfit(this.feed.id)
      }
    },
    toggleSaveOutfit () {
      this.toggleFavourite()
    }
  },
  created () {
    this.getFeed()
  },
  mounted () {
    let pusher = new window.Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: 'eu',
      encrypted: true
    })
    let self = this
    var channel = pusher.subscribe('outfit-pool')
    channel.bind('new', function () {
      self.getFeed()
    })
  }
}
</script>
